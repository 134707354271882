import request from '@/utils/request'
import constant from '@/constant'

function login(data) {
  var formData = new FormData();

  for (var key in data) {
    formData.append(key, data[key]);
  }

  return request.post(constant.serverUrl + '/login', formData)
}

function pageList(formData) {
  return request.post(constant.serverUrl + "/sys/user/pageList", formData);
}

function create() {
  return request.get(constant.serverUrl + "/sys/user/create");
}

function edit(id) {
  return request.get(constant.serverUrl + "/sys/user/edit/" + id);
}

function add(formModel) {
  return request.post(constant.serverUrl + "/sys/user/add", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel) {
  return request.post(constant.serverUrl + "/sys/user/update", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id) {
  return request.post(constant.serverUrl + "/sys/user/delete/" + id);
}

function batchRemove(idList) {
  return request.post(constant.serverUrl + "/sys/user/batchDelete", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function changeUserPassword(formData) {
  return request.post(constant.serverUrl + "/sys/user/changeUserPassword", formData);
}

function changeLoginPassword(formData) {
  return request.post(constant.serverUrl + "/sys/user/changeLoginPassword", formData);
}


function userInfo() {
  return request.get(constant.serverUrl + "/userInfo");
}


function bindWechat(userId) {
  var formData = new FormData();

  formData.append("expire_seconds", "3600");
  formData.append("action_name", "QR_STR_SCENE");
  formData.append("scene_str", process.env.VUE_APP_BIND_WECHAT_CODE + "," + userId);

  return request.post(constant.serverUrl + "/wechat/qrcode/create", formData);
}

function scanLogin(rnd) {
  var formData = new FormData();

  formData.append("expire_seconds", "3600");
  formData.append("action_name", "QR_STR_SCENE");
  formData.append("scene_str", process.env.VUE_APP_SCAN_CODE_LOGIN + "," + rnd);

  return request.post(constant.serverUrl + "/wechat/qrcode/create", formData);
}

function chargingScanLogin(chargingDeviceId) {
  var formData = new FormData();

  formData.append("expire_seconds", "3600");
  formData.append("action_name", "QR_LIMIT_STR_SCENE");
  formData.append("scene_str", process.env.VUE_APP_SCAN_CHARGING_CODE_LOGIN + "," + chargingDeviceId);

  return request.post(constant.serverUrl + "/wechat/qrcode/create", formData);
}

function queryScanResult(rnd){
  var formData = new FormData();
  formData.append("rnd", rnd);

  return request.post(constant.serverUrl + "/qrcode/queryScanResult", formData);
}


export default {
  login, pageList, create, edit, add, update, remove, batchRemove, userInfo,
  changeUserPassword,changeLoginPassword,bindWechat,scanLogin,queryScanResult,
  chargingScanLogin
}