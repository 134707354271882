<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="cachedViews">
        <router-view :key="key" />
      </keep-alive>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.fullPath
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/transition.scss';

.app-main{
  position: absolute;
  top: 0px;
  bottom: 0px;
  left:5px;
  right:0px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>