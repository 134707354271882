var routers = [
        {
                path: '/sys/user/list',
                name: 'SysUserList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/sys/user-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '用户管理'
                }
        },
        {
                path: '/sys/role/list',
                name: 'SysRoleList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/sys/role-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '角色管理'
                }
        },
        {
                path: '/sys/menu/list',
                name: 'SysMenuList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/sys/menu-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '菜单管理'
                }
        },
        {
                path: '/sys/permission/list',
                name: 'SysPermissionList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/sys/permission-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '接口权限管理'
                }
        },
        {
                path: '/sys/dataDictionary/list',
                name: 'SysDataDictionaryList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/sys/dataDictionary-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '数据字典管理'
                }
        },
        {
                path: '/sys/log/list',
                name: 'SysLogList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/sys/log-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '日志管理'
                }
        },
]

export default routers;