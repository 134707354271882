var routers = [
        {
                //考勤记录
                path: '/business/workAttendance/list',
                name: 'BusinessWorkAttendanceList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/business/workAttendance-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '考勤记录'
                }
        },
        {
                //补卡申请记录
                path: '/business/fillAttendance/list',
                name: 'BusinessFillAttendanceList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/business/fillAttendance-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '补卡申请记录'
                }
        },
        {
                //异地打卡记录
                path: '/business/clockOut/list',
                name: 'BusinessClockOutList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/business/clockOut-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '外勤打卡记录'
                }
        },
        {
                //请假记录
                path: '/business/requestForLeave/list',
                name: 'business-requestForLeave-list',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/business/requestForLeave-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '请假记录'
                }
        },
        {
                //账单推送管理
                path: '/business/billPush/list',
                name: 'businessBillPushList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/business/billPush-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '账单推送管理'
                }
        },
        {
                //账单推送统计
                path: '/business/billPush/statisticsList',
                name: 'businessBillPushStatisticsList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/business/billPush-statistics-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '账单推送统计'
                }
        },
        {
                //租户账单管理
                path: '/business/billPush/checkIn-list',
                name: 'businessbillPushCheckInList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/business/billPush-checkIn-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '租户账单管理'
                }
        },
        {
                //班次考勤表
                path: '/business/workScheduleReport/list',
                name: 'BusinessWorkScheduleReport',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/business/workScheduleReport.vue'),
                meta: {
                        roles: ["admin"],
                        title: '班次考勤表'
                }
        },
]

export default routers;