import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/views/layout'
import Home from '@/views/Home.vue'
import baseRouters from './modules/base'
import businessRouters from './modules/business'
import sysRouters from './modules/sys'
import docRouters from './modules/doc'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie

Vue.use(Router)

NProgress.configure({ showSpinner: true }) // NProgress Configuration

export const constantRoutes = [
  {
    path: '/layout',
    component: Layout,
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
          title: '控制台',
          affix: true
        }
      },
      ...sysRouters,
      ...baseRouters,
      ...businessRouters,
      ...docRouters,
    ]
  },
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/login',
    component: () => import('@/views/Login')
  },
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
];

// Array.prototype.push.apply(routes, caseRouters);
// Array.prototype.push.apply(routes, sysRouters);

var router = new Router({
  routes: constantRoutes
})

router.beforeEach((to, from, next) => {
  NProgress.start();
  console.log(`${to.path}`);

  const hasToken = getToken()

  if (hasToken != null) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/home' })
    }
    else {
      next();
    }

    NProgress.done();
  }
  else {
    if (to.path === '/login') {
      next();
    }
    else {
      next(`/login?redirect=${to.path}`);
    }

    NProgress.done();
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;