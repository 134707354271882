<template>
  <!--这里由于包裹了一层div所以原生collpase样式无法起作用-->
  <div class='menu-wrapper'>
    <el-scrollbar style="height:100%">
    <template v-for="item in routes">
      <template v-if="item.children==null || item.children.length==0">     
        <el-menu-item :index="item.menuUrl + ',' + item.menuName" :key="item.id">
            <i :class="item.icon"></i>
          <span>{{item.menuName}}</span>
        </el-menu-item>
      </template>
      <template v-else>
        <!--子菜单index不能重复，否则打开菜单时相同index菜单会同时开启-->
        <el-submenu :index="item.id" :key="item.id">
          <template slot="title">
            <i :class="item.icon"></i>
            <span>{{item.menuName}}</span>
          </template>
          <menu-tree-item class='nest-menu' :routes='item.children' :key="item.id"></menu-tree-item>
        </el-submenu>
      </template>
    </template>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  name: 'MenuTreeItem',
  props: {
    routes: {
      type: Array
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-menu--collapse {
    .menu-wrapper{
      .el-submenu {
        .el-submenu__title {
          span {
            height: 0;
            width: 0;
            overflow: hidden;
            visibility: hidden;
            display: inline-block;
          }
        }
      }
    }
  }
</style>
