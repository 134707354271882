import userApi from '@/api/sys/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { Message } from 'element-ui'

const state = {
    token: getToken(),
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    }
}

const actions = {
    login({ commit }, userInfo) {
        const { userName, password } = userInfo;

        return new Promise((resolve, reject) => {
            userApi.login({ userName: userName.trim(), password: password }).then(response => {
                var jsonData = response.data;
                
                console.log("result=" + jsonData.result);

                if(jsonData.result){
                    commit('SET_TOKEN', jsonData.data)
                    setToken(jsonData.data)
                }
                else{
                    Message.error(jsonData.message || 'Has Error')
                }
                
                resolve(jsonData.result)
            }).catch(error => {
                reject(error)
            })
        })
    },
    logout({ commit }) {
        return new Promise((resolve, reject) => {
            removeToken();
            commit('SET_TOKEN', null);

            resolve();
        });
    }

}

export default {
  namespaced: true, //必填，否则声明都为root,state,mutations,action 一定要对外声明后才能在外部调用
  state,
  mutations,
  actions
}