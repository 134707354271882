import Vue from 'vue'
import App from './App.vue'
import router from './routers'
import store from './store'
import echarts from 'echarts'
import './plugins/element.js'
import AxiosPlugin from './plugins/AxiosPlugin'
import './assets/iconfont/iconfont.css'
import { Message } from 'element-ui'
import AMap from "vue-amap"

Vue.config.productionTip = false
Vue.use(AxiosPlugin);
//Vue.use(ConfirmPlugin);
Vue.use(AMap);

AMap.initAMapApiLoader({
  key: "09abadb2e35fc61fd84042c518e9aebf", 
  plugin: ["AMap.Scale", "AMap.OverView", "AMap.ToolBar", "AMap.MapType"],
  uiVersion: "1.0.11", // ui库版本，不配置不加载,
  v: "1.4.4"
});

Vue.prototype.$echarts = echarts


var MY_MESSAGE_DEFAULT = {
  showClose: true
}
 
var MessageWrapper = function (options) {
  if (typeof (options) === 'object') {
    options = Object.assign({}, MY_MESSAGE_DEFAULT, options)
  }

  return Message(options)
}

for(var key in Message){
  MessageWrapper[key] = Message[key]
}

Vue.prototype.$message = MessageWrapper
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
