import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import { getToken, setToken, removeToken } from '@/utils/auth'

// post 跨域
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'

axios.interceptors.request.use(function (config) {
  config.headers['Authorization'] = getToken();
  
  return config;
},function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(
  response=>{
    console.log(response.data.code);

    var code = response.data.code;

    if(code==415){
      //Message.error(response.data.message);
      removeToken();
      window.location.href = "#/login";
    }
    else if(code==401){
      Message({
        message: response.data.message + "",
        type: 'error',
        duration: 3000
      })
    }

    return response;
  },
  error => {
    console.log(error);

    Message({
      message: error.message,
      type: 'error',
      duration: 2000
    })

    return error;
  }
)


export default axios;