var routers = [
        {
                //终端管理
                path: '/base/terminalInfo/list',
                name: 'BaseTerminalInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/terminalInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '终端管理'
                }
        },
        {
                //户号管理
                path: '/base/electricClientInfo/list',
                name: 'BaseElectricClientInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/electricClientInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '户号管理'
                }
        },
        {
                //电表管理
                path: '/base/electricMeterInfo/list',
                name: 'BaseElectricMeterInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/electricMeterInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '电表管理'
                }
        },
        {
                //充值流水
                path: '/base/rechargeRecord/list',
                name: 'BaseRechargeRecordList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/rechargeRecord-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '充值流水'
                }
        },
        {
                //物业充值流水
                path: '/base/rechargeRecordWater/list',
                name: 'BaseRechargeRecordWaterList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/rechargeRecordWater-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '物业充值流水'
                }
        },
        {
                //物业费充值记录
                path: '/base/rechargeRecordProperty/list',
                name: 'BaseRechargeRecordPropertyList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/rechargeRecordProperty-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '物业费充值记录'
                }
        },
        {
                //房间管理
                path: '/base/roomInfo/list',
                name: 'BaseRoomInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/roomInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '房间管理'
                }
        },
        {
                //线下充值
                path: '/base/rechange/list',
                name: 'BaseRechangeList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/rechange-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '线下充值'
                }
        },
        {
                //业主信息管理
                path: '/base/ownerInfo/list',
                name: 'BaseOwnerInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/ownerInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '业主信息管理'
                }
        },
        {
                //车位管理
                path: '/base/parkingInfo/list',
                name: 'BaseParkingInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/parkingInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '车位管理'
                }
        },
        {
                //车位申请
                path: '/base/parkingApply/list',
                name: 'BaseParkingApplyList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/parkingApply-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '车位申请'
                }
        },
        {
                //车辆白名单
                path: '/base/parkingWhite/list',
                name: 'BaseParkingWhiteList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/parkingWhite-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '车辆白名单'
                }
        },
        {
                //围墙报警
                path: '/base/alarmInfoFence/list',
                name: 'BaseAlarmInfoFenceList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/alarmInfo-fence-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '围墙报警'
                }
        },
        {
                //警戒报警
                path: '/base/alarmInfoAlert/list',
                name: 'BaseAlarmInfoAlertList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/alarmInfo-alert-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '警戒报警'
                }
        },
        {
                //投诉建议管理
                path: '/base/informationInfoComplaint/list',
                name: 'BaseInformationInfoComplaintList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/informationInfo-complaint-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '投诉建议管理'
                }
        },
        {
                //报事报修管理
                path: '/base/informationInfoWarranty/list',
                name: 'BaseInformationInfoWarrantyList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/informationInfo-warranty-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '报事报修管理'
                }
        },
        {
                //报事报修管理(免登录)
                path: '/base/informationInfoWarranty/list2',
                name: 'BaseInformationInfoWarrantyList2',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/informationInfo-warranty-list2.vue'),
                meta: {
                        roles: ["admin"],
                        title: '投诉建议管理'
                }
        },
        {
                //企业员工管理
                path: '/base/employeeInfo/list',
                name: 'BaseEmployeeInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/employeeInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '企业员工管理'
                }
        },
        {
                //单位管理
                path: '/base/companyInfo/list',
                name: 'BaseCompanyInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/companyInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '单位管理'
                }
        },
        {
                //人员管理
                path: '/base/personInfo/list',
                name: 'BasePersonInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/personInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '人员管理'
                }
        },
        {
                //设备管理
                path: '/base/deviceInfo/list',
                name: 'BaseDeviceInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/deviceInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '设备管理'
                }
        },
        {
                //进出记录
                path: '/base/personDeviceLog/list',
                name: 'BasePersonDeviceLogList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/personDeviceLog-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '进出记录'
                }
        },
        {
                //检测预警上报人员管理
                path: '/base/warningPusher/list',
                name: 'BaseWarningPusherList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/warningPusher-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '预警上报人员管理'
                }
        },
        {
                //通知公告
                path: '/base/messageNotice/list',
                name: 'BaseMessageNoticeList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/messageNotice-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '通知公告'
                }
        },
        {
                //异常上报
                path: '/base/messageReport/list',
                name: 'BaseMessageReportList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/messageReport-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '异常上报'
                }
        },
        {
                //打卡时间
                path: '/base/alarmConfig/list',
                name: 'BaseAlarmConfigList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/alarmConfig-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '考勤时间设置'
                }
        },
        {
                //测温统计
                path: '/base/temperatureRecord/list',
                name: 'BaseTemperatureRecordList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/temperatureRecord-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '测温统计'
                }
        },
        {
                //设备人员管理
                path: '/base/devicePerson/list',
                name: 'BaseDevicePersonList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/devicePerson-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '设备人员管理'
                }
        },
        {
                //节假日设置
                path: '/base/holidayInfo/list',
                name: 'BaseHolidayInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/holidayInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '节假日设置'
                }
        },
        {
                //打卡点管理
                path: '/base/punchLocation/list',
                name: 'BasePunchLocationList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/punchLocation-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '打卡点管理'
                }
        },
        {
                //打卡点打卡记录
                path: '/base/punchRecord/list',
                name: 'BasePunchRecordList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/punchRecord-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '打卡点打卡记录'
                }
        },
        {
                path: '/base/mobileBannerInfo/list',
                name: 'baseMobileBannerInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/mobileBannerInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '移动端幻灯片管理'
                }
        },
        {
                path: '/charging/chargingDevice/list',
                name: 'chargingChargingDeviceList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/charging/chargingDevice-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '充电桩管理'
                }
        },
        {
                path: '/charging/chargingRegUser/list',
                name: 'chargingChargingRegUserList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/charging/chargingRegUser-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '充电桩用户管理'
                }
        },
        {
                path: '/charging/chargingStation/list',
                name: 'chargingChargingStationList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/charging/chargingStation-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '站点管理'
                }
        },
        {
                path: '/charging/chargingRecord/list',
                name: 'chargingChargingRecordList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/charging/chargingRecord-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '充电记录'
                }
        },
        {
                path: '/charging/chargingMarketing/list',
                name: 'chargingChargingMarketingList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/charging/chargingMarketing-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '营销管理'
                }
        },
        {
                path: '/charging/chargingOrder/list',
                name: 'chargingChargingOrderList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/charging/chargingOrder-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '充值管理'
                }
        },
        {
                path: '/charging/chargingRefund/list',
                name: 'chargingChargingRefundList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/charging/chargingRefund-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '退款管理'
                }
        },
        {
                path: '/charging/chargingReport/list',
                name: 'chargingChargingReportList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/charging/chargingReport-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '日收入报表'
                }
        },
        {
                path: '/charging/shareProfitRole/list',
                name: 'chargingShareProfitRoleList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/charging/shareProfitRole-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '分润角色类型'
                }
        },
        {
                path: '/charging/applicationAmountRecord/list',
                name: 'chargingApplicationAmountRecordList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/charging/applicationAmountRecord-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '申请提现管理'
                }
        },
        {
                path: '/charging/merchantAccount/list',
                name: 'chargingMerchantAccountList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/charging/merchantAccount-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '商家账户管理'
                }
        },
        {
                path: '/charging/shareProfitRecord/list',
                name: 'chargingShareProfitRecordList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/charging/shareProfitRecord-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '分润流水记录'
                }
        },
        {
                path: '/charging/helpCenterInfo/list',
                name: 'chargingHelpCenterInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/charging/helpCenterInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '帮助中心'
                }
        },
        /////////////////////////////////////////小鹏管家2.0新增功能/////////////////////////////////////////////////////
        {
                path: '/base/personRoleInfo/list',
                name: 'basePersonRoleInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/personRoleInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '用户身份管理'
                }
        },
        {
                path: '/base/watchInfo/list',
                name: 'baseWatchInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/watchInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '老人手表管理'
                }
        },
        {
                path: '/base/watchMeasure/list',
                name: 'baseWatchMeasureList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/watchMeasure-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '手表消息管理'
                }
        },
        {
                path: '/base/message/list',
                name: 'baseMessageList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/messageInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '消息通知'
                }
        },
        {
                path: '/base/cameraInfo/list',
                name: 'baseCameraInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/cameraInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '摄像头管理'
                }
        },
        {
                path: '/base/iconInfo/list',
                name: 'baseIconInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/iconInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '图标管理'
                }
        },
        {
                //订单管理
                path: '/base/order/list',
                name: 'BaseOrderList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/order-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '订单管理'
                }
        },
        {
                path: '/base/companyStructureInfo/list',
                name: 'baseCompanyStructureInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/companyStructureInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '单位机构管理'
                }
        },
        {
                path: '/base/parkingWay/list',
                name: 'baseParkingWayList',
                component: () => import('@/views/base/parkingWay-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '车位租赁方式管理'
                }
        },
        {
                path: '/base/problemFeedback/list',
                name: 'baseProblemFeedback',
                component: () => import('@/views/base/problemFeedback-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '问题反馈管理'
                }
        },
        {
                path: '/base/oldPersonInfo/list',
                name: 'baseOldPersonInfo',
                component: () => import('@/views/old/oldPersonInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '长者管理'
                }
        },
        {
                path: '/base/applicationExamine/list',
                name: 'baseApplicationExamineList',
                component: () => import('@/views/base/applicationExamine-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '审批角色管理'
                }
        },
        {

                path: '/base/payService/list',
                name: 'basePayServiceList',
                component: () => import('@/views/base/payService-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '收费项目管理'
                }
        },
        {

                path: '/base/activityInfo/list',
                name: 'baseActivityInfo',
                component: () => import('@/views/base/activityInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '活动管理'
                }
        },
        {
                path: '/base/businessOut/list',
                name: 'baseBusinessOutList',
                component: () => import('@/views/base/businessOut-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '外勤申请记录'
                }
        },
        {
                path: '/base/checkinInfo/list',
                name: 'baseCheckinInfo',
                component: () => import('@/views/base/checkinInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '租户管理'
                }
        },
        {
                path: '/base/companyPayment/list',
                name: 'baseCompanyPayment',
                component: () => import('@/views/base/companyPayment-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '收款账户管理'
                }
        },
        {
                path: '/base/returnInfo/list',
                name: 'baseReturnInfoList',
                component: () => import('@/views/base/returnInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '返乡人员管理'
                }
        },
        {
                path: '/base/approvalConfig/list',
                name: 'baseApprovalConfigList',
                component: () => import('@/views/base/approvalConfig-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '审核层级管理'
                }
        },
        {
                path: '/base/workOver/list',
                name: 'baseWorkOverList',
                component: () => import('@/views/base/workOver-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '加班申请记录'
                }
        },
        {
                path: '/base/workSchedule/list',
                name: 'baseWorkScheduleList',
                component: () => import('@/views/base/workSchedule-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '班次考勤设置'
                }
        },
        {
                //学生每日健康情况-包含健康打卡和上报
                path: '/base/personHealthLedger/list',
                name: 'basePersonHealthLedgerList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/personHealthLedger-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '健康打卡情况'
                }
        },
        {
                //审批用户管理
                path: '/base/personApplication/list',
                name: 'basePersonApplicationList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/personApplication-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '注册员工审核'
                }
        },
        {
                //商户优惠卷管理
                path: '/base/parkingCoupon/list',
                name: 'baseParkingCouponList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/parkingCoupon-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '商户优惠卷管理'
                }
        },
        {
                //商户管理
                path: '/base/parkingMerchant/list',
                name: 'baseParkingMerchantList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/parkingMerchant-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '商户管理'
                }
        },
        {
                //停车收费记录
                path: '/base/parkingRecord/list',
                name: 'baseParkingRecordList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/parkingRecord-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '停车收费记录'
                }
        },
        {
                //车辆出入记录
                path: '/base/parkingRecordInOut/list',
                name: 'parkingRecordInOutList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/parkingRecordInOut-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '车辆出入记录'
                }
        },
        {
                //室内机管理
                path: '/base/indoorDeviceInfo/list',
                name: 'BaseIndoorDeviceInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/indoorDeviceInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '室内机管理'
                }
        },
        {
                //电表管理
                path: '/base/companyElectricityMeter/list',
                name: 'BaseCompanyElectricityMeterList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/companyElectricityMeter-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '电表管理'
                }
        },
        {
                //电表记录管理
                path: '/base/electricityMeterRecord/list',
                name: 'BaseElectricityMeterRecordList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/electricityMeterRecord-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '电表记录管理'
                }
        },
        {
                //社区卡片管理
                path: '/base/communityCard/list',
                name: 'BaseCommunityCardList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/communityCard-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '卡片管理'
                }
        },
        {
                //小区房屋管理
                path: '/base/communityRoom/list',
                name: 'BaseCommunityRoomList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/communityRoom-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '房屋管理'
                }
        },
        {
                //租户审核
                path: '/base/communityApprove/list',
                name: 'BaseCommunityApproveList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/communityApprove-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '业主审核'
                }
        },
        {
                //租户审核
                path: '/base/communityInfo/list',
                name: 'BaseCommunityInfoList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/base/communityInfo-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '业主管理'
                }
        }

]

export default routers;