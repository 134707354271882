<template>
  <el-container class="outter-container">
    <el-header>
      <h3>
        <img src="../../assets/logo.png" height="50" />
      </h3>
      <div class="user-info">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link" style="cursor:pointer;">
            <i class="el-icon-s-custom"></i>
            <span v-html="user.realName" id="userRealName" style="margin-right:10px;"></span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="editPwd">修改密码</el-dropdown-item>
            <el-dropdown-item command="bindWechat">微信绑定</el-dropdown-item>
            <el-dropdown-item command="exit">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div style="position:absolute;bottom:0px;left:255px;right:400px;">
        <tags-view />
      </div>
    </el-header>
    <el-container>
      <el-aside :width="sidebarWidth" :height="sidebarHeight" v-loading="loading" class="sidebar"> 
        <div class="sidebar-collpase-btn" @click="collapse=!collapse" style="cursor:pointer">
          <i :class="sidbarHandlerClass"></i>
        </div>
        <el-menu
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          @select="handleSelect"
          :collapse="collapse"
          :unique-opened="true"
          :collapse-transition="false"
        >
          <menu-tree-item :routes="menuList"></menu-tree-item>
        </el-menu>
      </el-aside>
      <el-main>
        <!-- <router-view /> -->
        <app-main />
      </el-main>
      <el-dialog
        title="修改密码"
        :visible.sync="dialogFormVisible"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        width="20%"
        :before-close="colseChange"
      >
        <el-form ref="form" :model="form" :rules="ruleValidate">
          <el-form-item label="旧密码" prop="oldPassword">
            <el-input
              v-model="form.oldPassword"
              placeholder="请输入新密码"
              style="width:100%"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword">
            <el-input
              v-model="form.newPassword"
              placeholder="请输入新密码"
              style="width:100%"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="重复新密码" prop="newPasswordTwo">
            <el-input
              v-model="form.newPasswordTwo"
              placeholder="请输入新密码"
              style="width:100%"
              show-password
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="colseChange">取 消</el-button>
          <el-button type="primary" @click="changePw">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="扫码绑定微信"
        :visible.sync="bindWechatVisible"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        width="340px"
      >
        <div v-loading="bindWechatLoading">
        <img :src="bindWechatUrl" style="width:300px;fit-object:cover;"/>
        </div>
      </el-dialog>
    </el-container>
  </el-container>
</template>
<script>
import MenuTreeItem from "@/components/MenuTreeItem";
import AppMain from './AppMain';
import TagsView from './TagsView';
import menuApi from "@/api/sys/menu";
import userApi from "@/api/sys/user";

export default {
  components: {
    "menu-tree-item": MenuTreeItem,
    "app-main": AppMain,
    "tags-view": TagsView
  },
  data() {
    let samePassword = (rule, value, callback) => {
      var self = this;
      var newPasswordTwo = value;
      var newPassword = self.form.newPassword;
      if (newPassword != newPasswordTwo) {
        return callback(new Error("两次密码输入不相同"));
      } else {
        return callback();
      }
    };

    return {
      ruleValidate: {
        oldPassword: [{ required: true, message: "不能为空", trigger: "blur" }],
        newPassword: [{ required: true, message: "不能为空", trigger: "blur" }],
        newPasswordTwo: [
          {
            required: true,
            message: "不能为空",
            trigger: "blur"
          },
          {
            validator: samePassword,
            trigger: "blur"
          }
        ]
      },
      menuList: [],
      loading: false,
      collapse: false,
      user: {},
      dialogFormVisible: false,
      form: {
        oldPassword: "",
        newPassword: "",
        newPasswordTwo: ""
      },
      sidebarHeight: window.innerHeight,      
      bindWechatVisible: false,
      bindWechatUrl: "",
      bindWechatLoading: false,
    };
  },
  computed: {
    sidebarWidth() {
      return !this.collapse ? "200px" : "64px";
    },
    sidbarHandlerClass() {
      return {
        iconfont: true,
        "icon-icon_left_arrow": this.collapse,
        "icon-icon_right_arrow": !this.collapse
      };
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleSelect(key, keyPath) {
      //console.log("select:", key, keyPath);
      var arr = key.split(",");

      //在store保存
      // this.$store.dispatch('tagsView/selectMenu', {
      //   url: arr[0],
      //   title: arr[1]
      // })

      this.$router.push({ path: arr[0]});
    },
    logout() {
      this.$store.dispatch("user/logout").then(() => {
        this.$router.push({ path: "/login" });
      });
    },
    openChangePwDialog() {
      var self = this;
      self.dialogFormVisible = true;
    },
    changePw() {
      var self = this;
      this.$refs["form"].validate(valid => {
        if (valid) {
          var formData = new FormData();
          formData.append("oldPassword", self.form.oldPassword);
          formData.append("newPassword", self.form.newPassword);

          userApi.changeLoginPassword(formData).then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.dialogFormVisible = false;
              self.form.oldPassword = "";
              self.form.newPassword = "";
              self.form.newPasswordTwo = "";
              self.$message({
                type: "success",
                message: "修改成功,下次登录生效!"
              });
            } else {
              self.$message({
                type: "warning",
                message: jsonData.message
              });
            }
          });
        }
      });
    },
    colseChange() {
      var self = this;
      self.form.oldPassword = "";
      self.form.newPassword = "";
      self.form.newPasswordTwo = "";
      self.dialogFormVisible = false;
      this.$refs["form"].resetFields();
    },
    openBindWechatDialog() {
      this.bindWechatVisible = true;
      this.bindWechatLoading = true;

      userApi.bindWechat(this.user.id).then(response=>{
        var jsonData = response.data;
        this.bindWechatLoading = false;

        if(jsonData.result){
          this.bindWechatUrl = jsonData.data;
        }
        else{
          this.$message.warning(jsonData.message);
        }
      });
    },
    handleCommand(command){
        if(command=="editPwd"){
          this.openChangePwDialog();
        }
        else if(command=="bindWechat"){
          this.openBindWechatDialog();
        }
        else if(command=="exit"){
          this.logout();
        }
    }
  },
  mounted() {
    this.loading = true;

    userApi.userInfo().then(resp => {
      if (resp.data.result) {
        this.user = resp.data.data;
      }
    });

    menuApi
      .getMenuTree()
      .then(response => {
        console.log(response);

        var jsonData = response.data;

        this.menuList = jsonData.data;

        this.loading = false;
      })
      .catch(exception => {
        this.$message.error(exception + "");
        this.loading = false;
      });
  }
};
</script>

<style lang="scss" scoped>
.outter-container {
  flex: 1;
}

.el-header {
  position: relative;
  background-color: #fff;
  color: #333;
  text-align: left;
  // border-bottom: 2px solid rgb(36,61,162);
  border-bottom: 2px solid #00aaff;
}

.el-header h3 {
  margin: 5px;
  padding: 0px;
}

.el-aside {
  color: #333;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.el-aside .el-menu {
  flex: 1;
  background-color: rgb(238, 238, 238);
}

.el-main {
  background-color: #fff;
  color: #333;
  text-align: center;
  padding: 0px;
  position: relative;
}

.el-menu-vertical-demo {
  text-align: left;
}

.user-info {
  position: absolute;
  right: 20px;
  bottom: 10px;
  font-size: 14px;
  line-height: 30px;

  a {
    color: blue;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}

.sidebar {
  position: relative;
  overflow: visible !important;
}

.sidebar-collpase-btn {
  position: absolute;
  width: 12px;
  height: 53px;
  border-radius: 0px 10px 10px 0px;
  top: 200px;
  right: -12px;
  background-color: #eeeeee;
  z-index: 1000;
  padding-top: 30px;
  padding-right: 4px;
  color: black;
  font-weight: bold;
}

.menu-wrapper{
  position: absolute;
  top:0px;
  bottom:0px;
  left:0px;
  right:0px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
</style>