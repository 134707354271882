<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/">控制台</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-card class="diagram_jkgs" style="padding-left:-10px">
      <el-button style="font-size:24px" type="primary" @click="openView">
        健康
        <br />公示
      </el-button>
    </el-card>
    <el-card class="diagram_top">
      <div class="ax_default3 xtsyqk_fyy">
        <div class="xtsyqk_fyy_60">
          <i class="el-icon-s-shop"></i>
          <span style="color:#01447D">
            <b v-text="companyNum"></b>
          </span>
          <span>总单位数量</span>
        </div>
      </div>
    </el-card>
    <el-card class="diagram_top">
      <div class="ax_default3 xtsyqk_fyy">
        <div class="xtsyqk_fyy_60">
          <i class="el-icon-s-custom"></i>
          <span style="color:#01447D">
            <b v-text="personNum"></b>
          </span>
          <span>总人数量</span>
        </div>
      </div>
    </el-card>
    <el-card class="diagram_top">
      <div class="ax_default xtsyqk_fyy">
        <div class="xtsyqk_fyy_60">
          <i class="el-icon-sunny"></i>
          <span style="color:#01447D">
            <b v-text="normalDays"></b>
          </span>
          <span>累积无异常天数</span>
        </div>
      </div>
    </el-card>
    <el-card class="diagram_extraWide">
      <div class="ax_default2 xtsyqk_fyy">
        <div class="xtsyqk_fyy_60">
          <i class="el-icon-search"></i>
          <span style="color:#01447D">
            <b v-text="dayCheckNum"></b>/
            <b v-text="totalCheckNum"></b>
          </span>
          <span>当日检测人次 / 累计检测人次</span>
        </div>
      </div>
    </el-card>
    <el-card class="diagram_extraWide">
      <div class="ax_default2 xtsyqk_fyy">
        <div class="xtsyqk_fyy_60">
          <i class="el-icon-warning-outline"></i>
          <span style="color:#01447D">
            <b v-text="dayAbnormalNum"></b>/
            <b v-text="totalAbnormalNum"></b>
          </span>
          <span>当日异常人数 / 累计异常人数</span>
        </div>
      </div>
    </el-card>
    <el-card class="diagram">
      <div id="everyDayMeasuringDiv" style="width:100%;height: 280px;"></div>
    </el-card>
  </div>
</template>
<script>
import Constant from "@/constant";
import workAttendanceApi from "@/api/business/workAttendance";
import companyInfoApi from "@/api/base/companyInfo";
import userApi from "@/api/sys/user";

import "nprogress/nprogress.css"; // progress bar style
import NProgress from "nprogress"; // progress bar
// @ is an alias to /src
export default {
  name: "Home",
  data() {
    return {
      companyNum: "0",
      totalAbnormalNum: "0",
      dayAbnormalNum: "0",
      personNum: "0",
      totalCheckNum: "0",
      dayCheckNum: "0",
      normalDays: "0",
      abnormalNum: [],
      normalNum: [],
      day: [],
      user: {}
    };
  },
  methods: {
    everyDayMeasuringView() {
      //每日测温结果统计
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(
        document.getElementById("everyDayMeasuringDiv")
      );

      // 绘制图表
      myChart.setOption({
        title: {
          text: "每日测温结果统计"
        },
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: ["体温正常", "体温异常"]
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLabel: {
            interval: 0,
            rotate: 40
          },
          data: this.day
        },
        yAxis: {
          type: "value",
          min: 0,
          name: "人数"
          //max: this.yAxisMaxNum
        },
        color: ["green", "red"],
        // backgroundColor: {
        //   type: "linear",
        //   x: 0,
        //   y: 0,
        //   x2: 0,
        //   y2: 1,
        //   colorStops: [
        //     {
        //       offset: 0,
        //       color: "red" // 0% 处的颜色
        //     },
        //     {
        //       offset: 1,
        //       color: "blue" // 100% 处的颜色
        //     }
        //   ],
        //   global: false // 缺省为 false
        // },
        series: [
          {
            name: "体温正常",
            type: "line",
            //stack: "总量",
            data: this.normalNum
          },
          {
            name: "体温异常",
            type: "line",
            data: this.abnormalNum
          }
        ]
      });
    },
    openView() {
      var companyId = this.user.companyId;
      //默认企业版
      var qrtext =
        process.env.VUE_APP_COMPANY_HEALTH_QY_URL + "?companyId=" + companyId;

      companyInfoApi.edit(companyId).then(function(response) {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          var dpUrl = jsonData.data.wallUrl;
          if (dpUrl == "" || dpUrl == null) {
            if ("2" == jsonData.data.type) {
              qrtext =
                process.env.VUE_APP_COMPANY_HEALTH_XY_URL +
                "?companyId=" +
                companyId;
            }
          } else {
            //填写了固定地址wallUrl
            qrtext = dpUrl + "?companyId=" + companyId;
          }

          window.open(qrtext);
        } else {
          self.$message.error(jsonData.message + "");
        }
      });
    }
  },
  async mounted() {
    var self = this;
    workAttendanceApi.getFirstData().then(function(response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.companyNum = jsonData.data.companyNum;
        self.totalAbnormalNum = jsonData.data.totalAbnormalNum;
        self.dayAbnormalNum = jsonData.data.dayAbnormalNum;
        self.personNum = jsonData.data.personNum;
        self.totalCheckNum = jsonData.data.totalCheckNum;
        self.dayCheckNum = jsonData.data.dayCheckNum;
        self.normalDays = jsonData.data.normalDays;
      }
      // companyNum: 27  总公司数量
      // totalAbnormalNum: 1  总异常数
      // dayAbnormalNum: 0  单日异常数
      // personNum: 1364 总人数
      // totalCheckNum: 2141 累计检测人次
      // dayCheckNum: 10当日检测人次
      // normalDays: 4 累积无异常天数
    });

    await workAttendanceApi.getFirstExcelData().then(function(response) {
      var jsonData = response.data;
      if (jsonData.result) {
        console.log(jsonData);
        self.abnormalNum = jsonData.data.abnormalNum;
        self.normalNum = jsonData.data.normalNum;
        self.day = jsonData.data.day;
      }
      //abnormalNum
      //normalNum
      //day
    });

    await userApi.userInfo().then(resp => {
      if (resp.data.result) {
        this.user = resp.data.data;
      }
    });

    this.everyDayMeasuringView();
  }
};
</script>
<style lang="scss" scoped>
.xtsyqk_fyy {
  height: 64px;
  margin-bottom: 40px;
}
.xtsyqk_fyy i {
  font-size: 26px;
}
.xtsyqk_fyy span b {
  font-size: 21px;
}
.xtsyqk_fyy span {
  display: block;
  text-align: center;
  font-size: 14px;
}
.xtsyqk_fyy_60 {
  width: 60%;
  float: center;
}
.xtsyqk_fyy_40 {
  width: 40%;
  float: left;
}
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.header {
  text-align: left;
}

.diagram_top {
  margin: 20px 20px;
  width: 140px;
  height: 120px;
  float: left;
}
.diagram {
  margin: 20px 20px;
  width: 100%;
  height: 400px;
  float: left;
}
.diagram_extraWide {
  margin: 20px 20px;
  width: 220px;
  height: 120px;
  float: left;
}
.ax_default {
  width: 180px;
}
.ax_default2 {
  width: 320px;
}
.diagram_jkgs {
  margin: 20px 20px;
  height: 120px;
  float: left;
}
.ax_default3 {
  width: 170px;
}
</style>