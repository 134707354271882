<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: "app",
  data() {
    return {};
  },
  methods: {
    
  }
};
</script>
<style rel="stylesheet/scss" lang="scss">
body{
  margin: 0px;
}

#app {
  margin: 0px;
  padding: 0px;
  background: #efefef;
  position:absolute;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  display:flex;
  flex-direction: column;
}

.sticky-bg{
  background-color:#fff;
}

.el-table__header thead tr th{
  background: #f1f6fa !important;
  padding: 10px 0px 10px 0px;
}

.el-form--label-top .el-form-item__label {
    display: inline-block;
    width: 100%;
    height: 24px;
    padding: 0;
    margin-bottom: 4px;
    float: none;
    text-align: left;
}

.el-table{
  margin-top:5px;
}

.demo-form-inline{
  .el-form-item{
    margin-bottom: 10px;
  }
}

.role-permission {
  .el-transfer-panel {
    border: 1px solid #ebeef5;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    display: inline-block;
    vertical-align: middle;
    width: 320px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
  }
}
</style>
