var routers = [
        {
                path: '/doc/masterFile/list',
                name: 'docMasterFileList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/doc/masterFile-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '公文审批管理'
                }
        },
        {
                path: '/doc/fileSharing/list',
                name: 'docFileSharingList',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/doc/fileSharing-list.vue'),
                meta: {
                        roles: ["admin"],
                        title: '文件共享管理'
                }
        }
]

export default routers;